let elRoot, elNotifications;

export const initNotificationsController = () => {
  /** Resize Event Listeners -------------------------------------------------*/
  elNotifications = document.getElementById('Notifications');
  if(elNotifications) {
    elRoot = document.documentElement;

    window.addEventListener('resize', onResizeThrottledNotifications);
    onThrottledResizeNotifications();
  }
}

/** Throttle Resize ----------------------------------------------------------*/
const onThrottledResizeNotifications = () => {
  elRoot.style.setProperty('--notifications-height', `${elNotifications.offsetHeight}px`);
}

const onResizeThrottledNotifications = () => window.throttle('resizeNotifications', onThrottledResizeNotifications, 500)
