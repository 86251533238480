export function accordions() {
  let question = document.getElementsByClassName("faqQuestion")

  for (let i = 0; i < question.length; i++) {
    question[i].addEventListener("click", function() {
      let target = this.getAttribute("data-target")
      let active = this.classList.toggle("active")

			const elAnswer = document.querySelector(`.faqAnswer[data-parent="${target}"]`)
			const answer = elAnswer.getAttribute("data-parent")

			const elPlus = document.querySelector(`.faqPlus[data-parent="${target}"]`)
			const plus = elPlus.getAttribute("data-parent")

			const elNegative = document.querySelector(`.faqNegative[data-parent="${target}"]`)
			const negative = elNegative.getAttribute("data-parent")

      if (target && active) {
        target === plus ? elPlus.classList.add("hidden") : ''
        target === negative ? elNegative.classList.remove("hidden") : ''
        target === answer ? elAnswer.classList.remove("hidden") : ''
      } else {
        target === plus ? elPlus.classList.remove("hidden") : ''
        target === negative ? elNegative.classList.add("hidden") : ''
        target === answer ? elAnswer.classList.add("hidden") : ''
      }
    })
  }
}