export const initNavigationController = () => {
  const elSubMenuTogglers = document.querySelectorAll('.submenu__toggler');
  elSubMenuTogglers.forEach((elToggle) => {
    elToggle.addEventListener('click', onSubMenuToggle)
  })
}

const onSubMenuToggle = (event) => {
  const parent = event.currentTarget.parentNode
  if(parent.classList.contains('submenu--open')) {
    parent.classList.remove('submenu--open');
  } else {
    parent.classList.add('submenu--open')
  }
}
