let scrollTopLast = 0;
let scrolldirection;

const handleIntersect = (entries) => {
  entries.forEach((entry) => {
		if (entry.isIntersecting && entry.target.classList.contains('is-last')) {
			entry.target.classList.add('force-opacity');
		} else {
			entry.target.classList.remove('force-opacity');
		}

		if(entry.isIntersecting && entry.target.classList.contains('is-last')) {
			console.log(entry.intersectionRatio)
		}

    if (entry.isIntersecting && entry.intersectionRatio > 0.29) {
      entry.target.classList.add('is-intersecting');
    } else {
      entry.target.classList.remove('is-intersecting');
    }

    if (entry.isIntersecting && entry.intersectionRatio > 0.49) {
      entry.target.classList.add('is-intersecting-mid');
    } else {
      entry.target.classList.remove('is-intersecting-mid');
    }
  });
};

const buildThresholdList = () => {
  let thresholds = [];
  const numSteps = 10;

  for (let i = 1; i <= numSteps; i++) {
    const ratio = i / numSteps;
    thresholds.push(ratio);
  }

  thresholds.push(0);
  return thresholds;
};

const createObserver = (fnHandler) => {
  const options = {
    root: null,
    rootMargin: '0px',
    threshold: buildThresholdList(),
  };

  return new IntersectionObserver(fnHandler, options);
};

const onScrolly = () => {
  const scrollTopCurrent = document.documentElement.scrollTop;

  if (scrollTopCurrent > scrollTopLast) {
    scrolldirection = 'down';
  } else if (scrollTopCurrent < scrollTopLast) {
    scrolldirection = 'up';
  }

  scrollTopLast = scrollTopCurrent <= 0 ? 0 : scrollTopCurrent; // For Mobile or negative scrolling
};

document.addEventListener('DOMContentLoaded', () => {
  const observer = createObserver(handleIntersect);
  const observables = document.querySelectorAll('.scrolly__cue');
  [...observables].map((observable) => {
    observer.observe(observable);
  });
});

document.addEventListener('scroll', onScrolly, false);
