import './index.css';
import './scss/base.scss';
import './scss/article.scss';
import './scss/typography.scss';
import './scss/fonts.scss';
import './scss/loadmorelist.scss';
import './scss/scrollbars.scss';
import './scss/scrolly.scss';

import { initMenuManager } from './js/menuManager.js';
import { initInboxController } from './js/inboxController.js';
import { initNavigationController } from './js/navigationController.js';
import { initNotificationsController } from './js/notificationsController.js';
import { accordions } from './js/accordion';

import './js/scrolly.js';

if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log('hmr');
  });
}

document.addEventListener('DOMContentLoaded', () => {
  initMenuManager();
  initInboxController();
  initNavigationController();
  initNotificationsController();
  accordions();

  // Remove the transitions-disabled class to enable transitions
  setTimeout(() => {
    document.body.classList.remove('transitions-disabled');
  }, 1000)

  // Get all the elements to show on scroll
  const targetsUp = document.querySelectorAll('.js-slideUp-on-scroll');
  const targetsFade = document.querySelectorAll('.js-fadeIn-on-scroll');

  const callbackUp = function (entriesUp) {
    entriesUp.forEach((entry) => {
      // Is the element in the viewport?
      if (entry.isIntersecting) {
        // Add the slide class:
        entry.target.classList.add('animate-slideUp');
      }
    });
  };

  const callbackFade = function (entriesLeft) {
    entriesLeft.forEach((entry) => {
      // Is the element in the viewport?
      if (entry.isIntersecting) {
        // Add the fadeIn class:
        entry.target.classList.add('animate-fadeIn');
      }
    });
  };

  const options = {
    root: null,
    threshold: 0.1,
    rootMargin: '0% 0% -8% 0%',
  };

  // Set up a new observer
  const observerUp = new IntersectionObserver(callbackUp, options);
  const observerFade = new IntersectionObserver(callbackFade);

  targetsUp.forEach(function (target) {
    // Hide the element
    target.classList.add('translateY(100px)', 'opacity-0');

    // Add the element to the watcher
    observerUp.observe(target);
  });

  // Loop through each of the target
  targetsFade.forEach(function (target) {
    // Hide the element
    target.classList.add('opacity-0');

    // Add the element to the watcher
    observerFade.observe(target);
  });
});

window.throttleTicks = {};
window.debounceTimers = {};

/** THROTTLE Utility ---------------------------------------------------*/
window.throttle = (name, fn, delay) => {
  if (!throttleTicks[name]) {
    setTimeout(() => {
      fn();
      throttleTicks[name] = false;
    }, delay);

    throttleTicks[name] = true;
  }
}

/** DEBOUNCE Utility ---------------------------------------------------*/
window.debounce = (name, fn, delay) => {
  clearTimeout(debounceTimers[name]);
  debounceTimers[name] = setTimeout(() => {
    fn()
  }, delay);
}
