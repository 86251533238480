export const initInboxController = () => {
  const elMenuInboxOpen = document.getElementById('MenuOpenInbox');
  elMenuInboxOpen?.addEventListener('click', onClickInboxOpen);
}

const onClickInboxOpen = (event) => {
  const parent = event.currentTarget.parentNode;

  fetch('/auth/api/set-read', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(response => response.json())
  .then(data => {
    if(data.success) {
      parent.classList.remove('new');
    }
  })
  .catch(error => console.error('Error:', error));
}
