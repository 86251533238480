let elMenuOpeners, elMenuClosers, elMenuPanels;
let isFrontPage = false;

const detachableIds = ['Inbox', 'Profile'];

/** INIT -------------------------------------------------------------------- */
export const initMenuManager = () => {
  const isFrontPage = location.pathname == '/';

  if(isFrontPage) {
    setNavBackgroundTransparency(true);
    window.onscroll = onScroll;
  }

  elMenuOpeners = [...document.querySelectorAll('[id^="MenuOpen"]')];
  elMenuClosers = [...document.querySelectorAll('[id^="MenuClose"]')];
  elMenuPanels = [...document.querySelectorAll('[id^="MenuPanel"]')];

  elMenuOpeners.forEach((elOpen) => {
    elOpen.addEventListener('click', onClickMenuOpen);
  })

  elMenuClosers.forEach((elClose) => {
    elClose.addEventListener('click', onClickMenuClose);
  })

  window.addEventListener('resize', onResizeThrottledMenu);
}

/** EVENT HANDLERS ---------------------------------------------------------- */
const onClickMenuOpen = (event) => {
  event.preventDefault();

  const groupId = getGroupId(event.currentTarget, 'MenuOpen');

  if(event.currentTarget.classList.contains('active')) {
    menuClose(groupId);
  }
  else {
    const otherGroupIds = getGroupIds(elMenuPanels, 'MenuPanel', groupId);

    otherGroupIds.forEach(id => {
      menuClose(id);
    });

    const ignoreExpand = detachableIds.includes(groupId) && !getIsMobile();
    menuOpen(groupId, ignoreExpand);
  }
}

const onClickMenuClose = (event) => {
  event.preventDefault();

  const groupId = getGroupId(event.currentTarget, 'MenuClose');

  menuClose(groupId);
}

const onScroll = () => {
  if (window.scrollY < 100) {
    setNavBackgroundTransparency(true);
  } else {
    setNavBackgroundTransparency(false);
  }
}

/** Throttle Resize ----------------------------------------------------------*/
/** @TODO  */
const onThrottledResizeMenu = () => {
  elMenuPanels.forEach(elMenuPanel => {
    if(elMenuPanel.classList.contains('open')) {
      const groupId = getGroupId(elMenuPanel, 'MenuPanel');
      if(detachableIds.includes(groupId)) {
        if(getIsMobile()) {
          document.body.classList.add('nav--expanded');
          document.body.classList.add('overflow-hidden');
        }
        else {
          document.body.classList.remove('nav--expanded');
          document.body.classList.remove('overflow-hidden');
        }
      }
    }
  })
}

const onResizeThrottledMenu = () => window.throttle('resizeMenu', onThrottledResizeMenu, 500)

/** METHODS ----------------------------------------------------------------- */
const menuOpen = (groupId, ignoreExpand) => {
  // console.log('menuOpen', groupId);

  menuToggle('open', ignoreExpand);

  const elMenuPanel = document.getElementById(`MenuPanel${groupId}`);
  const elMenuOpener = document.getElementById(`MenuOpen${groupId}`);

  if(elMenuPanel && !elMenuPanel.classList.contains('open')) {
    elMenuPanel.classList.add('open');
  }

  if(elMenuOpener && !elMenuOpener.classList.contains('active')) {
    elMenuOpener?.classList.add('active');
  }
}

const menuClose = (groupId) => {
  // console.log('menuClose', groupId);

  menuToggle('close');

  const elMenuPanel = document.getElementById(`MenuPanel${groupId}`);
  const elMenuOpener = document.getElementById(`MenuOpen${groupId}`);

  if(elMenuPanel && elMenuPanel.classList.contains('open')) {
    elMenuPanel.classList.remove('open');
  }

  if(elMenuOpener && elMenuOpener.classList.contains('active')) {
    elMenuOpener.classList.remove('active');
  }
}

const menuToggle = (type, ignoreExpand = false) => {
  // move elsewhere
  // if(!document.body.classList.contains('nav--transitionable')) {
  //   document.body.classList.add('nav--transitionable');
  // }

  if(type == 'open' && !ignoreExpand) {
    document.body.classList.add('nav--expanded');
    document.body.classList.add('overflow-hidden');
  }
  else {
    document.body.classList.remove('nav--expanded');
    document.body.classList.remove('overflow-hidden');
  }

  const isExpanded = document.body.classList.contains('nav--expanded')
  const isScrollable = document.body.scrollHeight > window.innerHeight;

  if(isExpanded && isFrontPage) {
    setNavBackgroundTransparency(false);
  } else if (isFrontPage) {
    onScroll();
  }

  if(isExpanded && isScrollable) {
    const scrollbarWidth = getScrollbarWidth();
    document.body.style.paddingRight = `${scrollbarWidth}px`
  } else {
    document.body.style.paddingRight = '0px'
  }
}


/** UTILS ------------------------------------------------------------------- */
const getGroupIds = (elements, prefix, excludeId) => {
  const elementIds = elements.map(element => getGroupId(element, prefix));

  if(excludeId) {
    return elementIds?.filter(id => id != excludeId);
  }

  return elementIds;
}

const getGroupId = (element, prefix) => {
  const regex = new RegExp(`^(${prefix})`);
  const groupId = element.id.replace(regex,'');

  return groupId;
}

const getIsMobile = () => window.innerWidth < 768;

const getScrollbarWidth = () => {
  // Creating invisible container
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement('div');
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

  // Removing temporary elements from the DOM
  outer.parentNode.removeChild(outer);

  return scrollbarWidth;
}

const setNavBackgroundTransparency = (isTransparent) => {
  if(isTransparent) {
    document.body.classList.add('nav--transparent');
  } else {
    document.body.classList.remove('nav--transparent');
  }
}
